/* Built In Imports */
import { useEffect, useState } from "react";

/* External Imports */
import { Box } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import config from '@config';
import RenderSections from '@shi/common/RenderSections';
import WrapLayout from '@shi/components/Layout/WrapLayout';
import HeadComponent from '@shi/components/Utility/HeadComponent';

/* Services */
import { getCurrentRegion } from "@Services/commonService";
import { getLocale } from '@shi/Utility/Shared/SharedService';
import { performRequest } from '@utils/microsites/DatoCMSHelperService';
import { getMenuQuery, getQuery } from '@utils/microsites/SHIGqlQueries';
/**
 *
 * @param {*} pageData
 * @returns
 */
const ShivangaInner = ({ pageData, region, lang }) => {
  const [currentRegion, setCurrentRegion] = useState('');
  // const [tabNumber, setTabNumber] = useState('1');
  const [isMobile, setIsMobile] = useState();
  // let userRegion = '';
  // if (region != 'in' && currentRegion != 'IN') {
  //   userRegion = '&country_code=' + currentRegion;
  // } else {
  //   userRegion = '';
  // }
  // const tabToggle = e => {
  //   const tabActive = e.target.id.replace(/\D/g, '');
  //   setTabNumber(tabActive);
  //   let matches = document?.querySelectorAll('#sadhana-details-tabs .inactive');

  //   matches.forEach(function (item) {
  //     item.classList.remove('active');
  //     e.target.classList.add('active');
  //   });
  //   const anchorName = '#' + e.target.id + 'c';
  //   window.location.href = anchorName;
  // };

  useEffect(() => {
    localData(region, lang);
  }, [region, lang]);

  const localData = async (region, lang) => {
    const reg = await getCurrentRegion();
    if (reg?.country) {
      setCurrentRegion(reg.country);
    }
  };
  // console.log('pageData', pageData);

  const checkForDevice = () => {
    if (typeof window !== 'undefined') {
      // if (window.innerWidth < 767.98) {
      setIsMobile(true);
      // }
    }
  };

  const upcoming = () => {
    const tab1 = document.querySelector('#tab-1');
    const tab2 = document.querySelector('#tab-2');
    const tab3 = document.querySelector('#tab-3');
    // const tab4 = document.querySelector('#tab-4');
    const tab_content1 = document.querySelector('#tab-content-1');
    const tab_content2 = document.querySelector('#tab-content-2');
    const tab_content3 = document.querySelector('#tab-content-3');
    // const tab_content4 = document.querySelector('#tab-content-4');
    if (window.pageYOffset >= tab_content1?.offsetTop) {
      tab2?.classList.remove('active');
      tab3?.classList.remove('active');
      // tab4.classList.remove('active');
      tab1?.classList.add('active');
      if (window.pageYOffset >= tab_content2?.offsetTop) {
        tab1?.classList.remove('active');
        tab3?.classList.remove('active');
        // tab4.classList.remove('active');
        tab2?.classList.add('active');
        if (window.pageYOffset >= tab_content3?.offsetTop) {
          tab1?.classList.remove('active');
          tab2?.classList.remove('active');
          // tab4.classList.remove('active');
          tab3?.classList.add('active');
        }
      }
    }
  };

  useEffect(() => {
    checkForDevice();
    window.addEventListener('scroll', upcoming);
  });

  return (
    <WrapLayout pageData={pageData}>
      <HeadComponent
        data={pageData?._seoMetaTags || pageData?.seoFields}
        availableLocals={pageData.availableLocales}
      >
        {' '}
      </HeadComponent>
      <Box as="section" className="container-article" p="0" bgColor="#FFEBC7">
        {pageData?.body?.length && pageData?.body?.length
          ? pageData.body?.map((section, i) => {
            return (
              <RenderSections
                pageData={pageData}
                section={section}
                region={region}
                lang={lang}
                key={nanoid(4)}
              />
            );
          })
          : null}
        <style global jsx>{`
        .event-text a {
          color: #cf4824;
        }
        .inactive {
          color: #595959;
        }
        .active {
          border-color: #ce8200 !important;
          color: #222;
        }
        body {
          overflow-x: hidden;
        }
        .contactdetail a,
        .contactdetail .contacttext mark,
        .upcomedetail mark,
        .sadhanatext mark,
        .sadhana_download mark,
        #tab-content-1 .tab-content mark,
        .sadhana_list mark,
        .sadhana_list a {
          color: #ca4e2a;
          background: transparent;
          text-decoration: none;
        }
        #tab-content-1 .chakra-collapse {
          height: auto !important;
        }
        .sadhana_download a {
          color: #ca4e2a;
          text-decoration: none;
        }
        .upcomedetail p {
          font-style: normal;
        }
      `}</style>
      </Box>
    </WrapLayout>
  );
};

export const getStaticPaths = async () => {

  const shivangaPaths = [
    `/shivanga/in/en/sadhana`,
    `/shivanga/in/en/spurthi`,
    `/shivanga/in/en/kailasha-vadyam`,
    `/shivanga/in/en/temple-cleaning`,
    `/shivanga/in/en/velliangiri-cleaning-drive`,
    `/shivanga/in/en/donate`,
    `/shivanga/in/en/privacy-policy`,
    `/shivanga/in/en/terms-of-use`,


    `/shivanga/in/ta/sadhana`,
    `/shivanga/in/ta/spurthi`,
    `/shivanga/in/ta/kailasha-vadyam`,
    `/shivanga/in/ta/temple-cleaning`,
    `/shivanga/in/ta/velliangiri-cleaning-drive`,
    `/shivanga/in/ta/donate`,
    `/shivanga/in/ta/privacy-policy`,
    `/shivanga/in/ta/terms-of-use`,


    `/shivanga/in/te/sadhana`,
    `/shivanga/in/te/spurthi`,
    `/shivanga/in/te/kailasha-vadyam`,
    `/shivanga/in/te/temple-cleaning`,
    `/shivanga/in/te/velliangiri-cleaning-drive`,
    `/shivanga/in/te/donate`,
    `/shivanga/in/te/privacy-policy`,
    `/shivanga/in/te/terms-of-use`,


    `/shivanga/in/hi/sadhana`,
    `/shivanga/in/hi/spurthi`,
    `/shivanga/in/hi/kailasha-vadyam`,
    `/shivanga/in/hi/temple-cleaning`,
    `/shivanga/in/hi/velliangiri-cleaning-drive`,
    `/shivanga/in/hi/donate`,
    `/shivanga/in/hi/privacy-policy`,
    `/shivanga/in/hi/terms-of-use`,


    `/shivanga/in/ml/sadhana`,
    `/shivanga/in/ml/spurthi`,
    `/shivanga/in/ml/kailasha-vadyam`,
    `/shivanga/in/ml/temple-cleaning`,
    `/shivanga/in/ml/velliangiri-cleaning-drive`,
    `/shivanga/in/ml/donate`,
    `/shivanga/in/ml/privacy-policy`,
    `/shivanga/in/ml/terms-of-use`,


    `/shivanga/in/kn/sadhana`,
    `/shivanga/in/kn/spurthi`,
    `/shivanga/in/kn/kailasha-vadyam`,
    `/shivanga/in/kn/temple-cleaning`,
    `/shivanga/in/kn/velliangiri-cleaning-drive`,
    `/shivanga/in/kn/donate`,
    `/shivanga/in/kn/privacy-policy`,
    `/shivanga/in/kn/terms-of-use`,


    `/shivanga/global/en/sadhana`,
    `/shivanga/us/en/sadhana`,
    `/shivanga/uk/en/sadhana`,
    `/shivanga/my/en/sadhana`,
    `/shivanga/sg/en/sadhana`,
    `/shivanga/ca/en/sadhana`,
    `/shivanga/au/en/sadhana`,

    `/shivanga/global/en/kailasha-vadyam`,
    `/shivanga/us/en/kailasha-vadyam`,
    `/shivanga/uk/en/kailasha-vadyam`,
    `/shivanga/my/en/kailasha-vadyam`,
    `/shivanga/sg/en/kailasha-vadyam`,
    `/shivanga/ca/en/kailasha-vadyam`,
    `/shivanga/au/en/kailasha-vadyam`,

    `/shivanga/global/en/temple-cleaning`,
    `/shivanga/us/en/temple-cleaning`,
    `/shivanga/uk/en/temple-cleaning`,
    `/shivanga/my/en/temple-cleaning`,
    `/shivanga/sg/en/temple-cleaning`,
    `/shivanga/ca/en/temple-cleaning`,
    `/shivanga/au/en/temple-cleaning`,

    `/shivanga/global/en/velliangiri-cleaning-drive`,
    `/shivanga/us/en/velliangiri-cleaning-drive`,
    `/shivanga/uk/en/velliangiri-cleaning-drive`,
    `/shivanga/my/en/velliangiri-cleaning-drive`,
    `/shivanga/sg/en/velliangiri-cleaning-drive`,
    `/shivanga/ca/en/velliangiri-cleaning-drive`,
    `/shivanga/au/en/velliangiri-cleaning-drive`,

    `/shivanga/global/en/donate`,
    `/shivanga/us/en/donate`,
    `/shivanga/uk/en/donate`,
    `/shivanga/my/en/donate`,
    `/shivanga/sg/en/donate`,
    `/shivanga/ca/en/donate`,
    `/shivanga/au/en/donate`,

    `/shivanga/global/en/spurthi`,
    `/shivanga/us/en/spurthi`,
    `/shivanga/uk/en/spurthi`,
    `/shivanga/my/en/spurthi`,
    `/shivanga/sg/en/spurthi`,
    `/shivanga/ca/en/spurthi`,
    `/shivanga/au/en/spurthi`,

    `/shivanga/global/en/terms-of-use`,
    `/shivanga/us/en/terms-of-use`,
    `/shivanga/uk/en/terms-of-use`,
    `/shivanga/my/en/terms-of-use`,
    `/shivanga/sg/en/terms-of-use`,
    `/shivanga/ca/en/terms-of-use`,
    `/shivanga/au/en/terms-of-use`,

    `/shivanga/global/en/privacy-policy`,
    `/shivanga/us/en/privacy-policy`,
    `/shivanga/uk/en/privacy-policy`,
    `/shivanga/my/en/privacy-policy`,
    `/shivanga/sg/en/privacy-policy`,
    `/shivanga/ca/en/privacy-policy`,
    `/shivanga/au/en/privacy-policy`,
  ]
  return {
    paths: shivangaPaths,
    fallback: false,
  };
};

export const getStaticProps = async ({
  preview,
  previewData,
  params: { region, lang, slug },
}) => {
  const headerMenuData = await performRequest(
    getMenuQuery('shivangamainmenu'),
    'shivangamainmenu'
  );
  const footerData = await performRequest(
    getMenuQuery('shivangafooter'),
    'shivangafooter'
  );
  // const response = await performRequest(getQuery(`${slug}`));
  // let pageData = response;
  const response = await performRequest(getQuery(slug, getLocale(region, lang)));
  let pageData = response.allShivangaLandingPages[0] || [];
  // let pageData = response?.allShivangaLandingPages;

  pageData = JSON.stringify(pageData).replace(
    /https:\/\/www.datocms-assets.com\//g,
    `${config.staticPath}/d/`
  );
  pageData = JSON.parse(pageData);

  return {
    props: {
      headerMenuData: headerMenuData || null,
      pageData: pageData || '',
      footerData: footerData || null,
      isPreview: !!preview,
      lang: lang,
      region: region,
      menuType: 'shivangamainmenu',
    },
  };
};

export default ShivangaInner;
